<template>
    <div>
        <ContentHeader title="Laporan Pelayanan Klinik" subTitle="Laporan Penyakit" url="laporan-pelayanan-klinik" />
        <div class="content-header">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Data Laporan Penyakit</h3>
                    </div>
                    <div class="card-body" style="display: block;">
                        <div class="row d-flex justify-content-end">
                            <el-button @click="handleExportExcel" :loading="loadingExcel">Export Excel</el-button>
                        </div>
                        <div class="row" v-if="toogleCabang">
                            <div class="col-lg-4">
                                <label>Pilih Cabang</label>
                                <multiselect v-model="cabang_select_value" deselect-label="Can't remove this value"
                                    track-by="nama" label="nama" placeholder="" :options="cabang"
                                    :loading="loading_cabang" :allow-empty="false" @update:model-value="getDataResult">
                                </multiselect>
                            </div>
                        </div>
                        <div class="row align-items-center mt-2">
                            <div class="col-lg-2">
                                <span>Periode Awal</span>
                                <input type="date" class="form-control" v-model="periode_awal">
                            </div>
                            <div class="col-lg-2">
                                <span>Periode Akhir</span>
                                <input type="date" class="form-control" v-model="periode_akhir">
                            </div>
                            <div class="col-lg-1 pt-4">
                                <button class="btn btn-primary" @click="getDataResult">Filter</button>
                            </div>
                            <!-- <div class="col-lg-2">
                                <span>Periode</span>
                                <select class="custom-select form-control" v-model="filter.jenis_periode">
                                    <option value="emptyParameter" disabled label="- Pilih Periode -"></option>
                                    <option value="tanggal" label="Tanggal"></option>
                                    <option value="bulan" label="Bulan"></option>
                                    <option value="tahun" label="Tahun"></option>
                                </select>
                            </div>
                            <div class="col-lg-7"> 
                                <el-date-picker v-if="filter.jenis_periode == 'tanggal'" class="mt-4 mr-3" value-format="YYYY-MM-DD" v-model="filter.value" format="DD/MM/YYYY" placeholder="Pilih Tanggal"></el-date-picker>
                                <el-select v-else-if="filter.jenis_periode == 'bulan'" v-model="filter.value" class="mt-4 mr-3" placeholder="Pilih Bulan">
                                    <el-option v-for="(row, index) in bulan" :key="index" :value="row.value" :label="row.label"></el-option>
                                </el-select>
                                <el-date-picker v-else-if="filter.jenis_periode == 'tahun'" v-model="filter.value" class="mt-4 mr-3" type="year" value-format="YYYY" placeholder="Pilih Tahun"></el-date-picker>
                                <el-button class="mt-4" :loading="memuat_data" @click="getDataResult()">Cari</el-button>
                            </div> -->
                        </div>
                        <table class="table table-bordered table-hover mt-4">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-sm">NO</th>
                                    <th scope="col" class="text-sm">TANGGAL</th>
                                    <th scope="col" class="text-sm">PASIEN</th>
                                    <th scope="col" class="text-sm">ALAMAT</th>
                                    <th scope="col" class="text-sm">DOKTER</th>
                                    <th scope="col" class="text-sm">USIA</th>
                                    <th scope="col" class="text-sm">L/P</th>
                                    <th scope="col" class="text-sm">LAYANAN</th>
                                    <th scope="col" class="text-sm">DIAGNOSA</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in data_result" :key="row.id">
                                    <td class="text-sm align-middle">{{ index + 1 }}</td>
                                    <td class="text-sm align-middle" style="min-width: 100px;">{{
                                        dayjs(row.created_at).format('DD-MM-YYYY')
                                    }}</td>
                                    <td class="text-sm align-middle">{{ row.pasien?.nama }}</td>
                                    <td class="text-sm align-middle">{{ row.pasien?.alamat_lengkap }}</td>
                                    <td class="text-sm align-middle">{{ row.dokter?.nama_lengkap }}</td>
                                    <td class="text-sm align-middle">{{
                                        row.pasien.tanggal_lahir ?
                                            umur(row.pasien?.tanggal_lahir) + ' Tahun' : ''
                                    }}</td>
                                    <td class="text-sm align-middle">{{ row.pasien?.jenis_kelamin }}</td>
                                    <td class="text-sm align-middle">{{ row.layanan?.nama }}</td>
                                    <td class="text-sm">
                                        <p v-for="(icdx, index) in row.nama_icdx" :key="index">{{ `-
                                        ${row.kode_icdx[index]} - ${icdx}` }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
                        <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted, reactive, watch } from 'vue'
import store from '@/store'
import axios from 'axios'
import dayjs from 'dayjs'
import umur from '@/umur'
import Swal from 'sweetalert2'

const user = computed(() => store.getters['auth/user'])
const data_result = ref([])
const cek_data = ref(false)
const memuat_data = ref(false)
const periode_awal = ref('')
const periode_akhir = ref('')
const total = ref('')
const filter = reactive({
    jenis_periode: 'emptyParameter',
    value: null,
})

watch(() => filter.jenis_periode, () => filter.value = null)

const getDataResult = async () => {
    data_result.value = []
    total.value = ''
    memuat_data.value = true
    cek_data.value = false
    let cabang_id = ''
    let tanggal_awal = ''
    let tanggal_akhir = ''

    if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
    } else {
        cabang_id = cabang_select_value.value.id
    }

    if (periode_awal.value == '' && periode_akhir.value == '') {
        tanggal_awal = 'emptyParameter'
        tanggal_akhir = 'emptyParameter'
    } else {
        tanggal_awal = periode_awal.value
        tanggal_akhir = periode_akhir.value
    }

    // let filterValue = filter.value ? filter.value : 'emptyParameter'

    let { data } = await axios.get(`api/laporan/getLaporanPenyakit/${cabang_id}/${tanggal_awal}/${tanggal_akhir}`)
    if (data != 'kosong') {
        memuat_data.value = false
        cek_data.value = false
        data_result.value = data
    } else {
        cek_data.value = true
        memuat_data.value = false
        data_result.value = []
    }
}

const toogleCabang = ref(false)
const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
    let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

    if (response.data.jenis == 'pusat') {
        toogleCabang.value = true
        let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)

        if (data != 'kosong') {
            cabang.value = data
            loading_cabang.value = false
        } else {
            cabang.value = []
            loading_cabang.value = false
        }
    }
}

// const bulan = ref([
//     { value: '01', label:'Januari' },
//     { value: '02', label:'Februari' },
//     { value: '03', label:'Maret' },
//     { value: '04', label:'April' },
//     { value: '05', label:'Mei' },
//     { value: '06', label:'Juni' },
//     { value: '07', label:'Juli' },
//     { value: '08', label:'Agustus' },
//     { value: '09', label:'September' },
//     { value: '10', label:'Oktober' },
//     { value: '11', label:'November' },
//     { value: '12', label:'Desember' },
// ])

const loadingExcel = ref(false)
const handleExportExcel = async () => {
    loadingExcel.value = true
    let cabang_id = ''
    let tanggal_awal = ''
    let tanggal_akhir = ''

    if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
    } else {
        cabang_id = cabang_select_value.value.id
    }

    if (periode_awal.value == '' && periode_akhir.value == '') {
        tanggal_awal = 'emptyParameter'
        tanggal_akhir = 'emptyParameter'
    } else {
        tanggal_awal = periode_awal.value
        tanggal_akhir = periode_akhir.value
    }

    // let filterValue = filter.value ? filter.value : 'emptyParameter'

    await axios.get(`api/export/laporan_penyakit/${cabang_id}/${tanggal_awal}/${tanggal_akhir}`, { responseType: 'blob' })
        .then((response) => {
            console.log(response)
            let blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
            let url = window.URL || window.webkitURL;
            let fileUrl = url.createObjectURL(blob)
            let element = document.createElement('a')
            element.href = fileUrl;
            element.setAttribute('download', `laporan-penyakit.xlsx`)
            document.body.appendChild(element)
            element.click()
            window.URL.revokeObjectURL(blob)
        })
        .catch(() => {
            Swal.fire({
                title: 'Gagal!',
                text: 'Gagal mengeksport data',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500
            })
        })
        .finally(() => {
            loadingExcel.value = false
        })
}


onMounted(() => {
    getCabang()
    getDataResult()
})
</script>